/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */
/* stylelint-disable property-no-unknown */

.CheckoutOrderSummary {
    @include desktop {
        display: flex;
        flex-direction: column;

        &-Header {
            order: -1;
            display: flex;

            .Edit {
                margin-left: auto;
                font-size: 20px;
            }
        }

        &-OrderTotals {
            order: -1;
        }

        div#checkout_pay_summary {
            margin-block-end: 20px;
        }
    }

    @include mobile {
        margin-block-start: 10px;
    }

    h2 {
        font-weight: 400;
    }

    &-ExpandableContent {
        padding: 0 10px;

        .CartItem .freeshippingindicator-item {
            left: 0;
            top: 0;
        }
    }

    &-ActionsAdditional {
        margin: 15px 0 0 0;
        padding-bottom: 10px;
        padding-block-end: 10px;

        .strike {
            display: block;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            padding-block-end: 5px;
        }

        .strike > span {
            position: relative;
            display: inline-block;
        }

        .strike > span::before,
        .strike > span::after {
            content: "";
            position: absolute;
            inset-block-start: 50%;
            width: 9999px;
            height: 1px;
            background: var(--primary-divider-color);
        }

        .strike > span::before {
            right: 100%;
            margin-inline-end: 15px;
        }

        .strike > span::after {
            inset-inline-start: 100%;
            margin-left: 15px;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }

    &-SummaryItem {
        padding: 8px 0;

        strong.CheckoutOrderSummary-Text,
        .CheckoutOrderSummary-Price {
            font-family: regular-bold, sans-serif !important;
            font-size: 18px;
            font-weight: 400;
            color: var(--primary-text-color);
            margin-left: auto;
            flex: unset;
            text-align: right;

            @include mobile {
                font-size: 16px;
            }
        }
    }

    &-SummaryItem_isTotal {
        text-align: end;
    }

    &-OrderTotals {
        padding: 5px 0;
        display: flex;
        flex-direction: column;

        dt {
            display: flex;
            justify-content: space-between;
            padding: 8px 0;

            strong {
                font-family: regular-bold, sans-serif !important;
                font-size: 16px;
                font-weight: 400;
            }

            &.grand_total {
                order: 1;
                font-weight: 600;
                border-top: 1px solid var(--secondary-base-color);
                border-bottom: 1px double var(--secondary-base-color);
                padding: 15px 0;
                margin-block-start: 10px;
                font-size: 16px;

                strong {
                    font-size: 20px;
                }
            }

            &.order_excl {
                order: 2;
                color: #999;
            }

            &.discount {
                text-transform: capitalize;
            }
        }
    }

    &-Terms {
        color: var(--text-gray);
        font-family: regular-book, sans-serif;
        font-size: 14px;
        margin-bottom: 15px;
        background-color: #fff;
        
        a {
            color: var(--text-gray-color);
            text-decoration: underline;

            &:hover {
                color: #222;
                text-decoration: underline;
            }
        }
    }
}
