/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */

.front {
    &-all {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        color: var(--text-light-black-color);
    }

    &-main-container {
        width: 700px;
        max-width: 90%;
        background-color: #fff;
        position: relative;
        max-height: 90vh;
        height: auto;

        button.front-close-btn {
            position: absolute;
            inset-block-start: -12px;
            right: 12px;

            &:hover {
                .CloseIcon {
                    fill: var(--text-red-color);
                }
            }
        }

        ul.Pagination {
            margin-top: 0;
            
            li {
                margin: 0 5px;
            }
        }
    }

    &-head-title,
    &-search-element {
        padding: 0 25px;

        h3 {
            font-family: regular-bold, sans-serif;
            text-transform: uppercase;
            font-size: 20px;
        }
    }

    &-total-address {
        padding: 8px 25px;
        border-bottom: 1px solid var(--checkout-delivery-option-border-color);
    }

    &-body-container {
        padding: 20px 25px;
        max-height: calc(100vh - 330px);
        overflow-y: auto;

        .front-address-conatiner {
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--checkout-delivery-option-border-color);
            padding: 10px 0;

            &:last-child {
                border-bottom: none;
            }

            .front-address-btn {
                display: flex;
                align-items: center;

                .text-df {
                    text-transform: uppercase;
                    margin-inline-end: 30px;
                    color: #888;
                    font-family: regular-bold, sans-serif;
                    font-size: 16px;
                    margin-right: 15px;
                }

                .btn-default[disabled] {
                    pointer-events: none;
                }
            }
        }

        .front-address-text {
            flex: 1;
            line-height: 1.3;
        }
    }
}
