/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */

.Checkout {
    .CheckoutDeliveryOptionsWrap {
        .shipLoaderTeaxt {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 9;
            text-align: center;
            background: #fff;
            padding: 4px 10px;
            border-radius: 4px;
            margin-top: 14px;
            width: 358px;
            max-width: 98%;
        }

        div.Loader-Main {
            margin-top: -30px;
        }
    }
}

.KeyValueTable {
    &-Heading {
        font-size: 14px;

        .RadioButtonIcon {
            float: inline-start;
            inset-block-start: 0 !important;
            margin-inline-end: 4px;
        }
    }

    &-Wrapper {
        max-width: calc(100vw - 2rem);
    }

    button.GnA-pay {
        background: #000;
        display: block;
        margin: 10px auto 0;
        height: 45px;
        width: 255px;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 55px;

        &.Google-pay {
            background-image: url(../../images/payment_icons/g-pay.svg);
        }

        &.Apple-pay {
            background-image: url(../../images/payment_icons/apple-pay.svg);
        }
    }
}
