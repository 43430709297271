/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */

.Checkout {
    .Checkout-Step {
        max-width: 430px;
        margin: 0 auto;
        position: static;

        @include mobile {
            h2.Checkout-Heading {
                margin-block-start: 15px;
            }
        }

        .Field_type_checkbox label:last-of-type,
        .Field_type_radio label:last-of-type {
            padding: 0;
        }

        .CheckoutAddressForm {
            max-width: 100%;

            @include mobile {
                margin-block-end: 45px;
            }

            .FieldForm-Fields {
                display: flex;
                flex-direction: column;
                grid-template-columns: unset;
                grid-column-gap: unset;

                .Field-Message {
                    background-size: 6px;
                    background-position: left 2px;
                    padding-left: 12px;
                    position: absolute;
                    font-size: 11px;
                    margin-top: 1px;
                }

                .Field {
                    margin-block-start: 18px;

                    &.Field_type_ {
                        display: none;

                        &VatSucc,
                        &VatError,
                        &Po_box {
                            position: absolute;
                            bottom: 10px;
                            left: 51%;

                            @include mobile {
                                inset-inline: 0;
                                bottom: 24px;
                            }

                            label {
                                font-size: 13px;
                                background: #e5efe5;
                                color: #006400;
                                padding: 4px 8px;
                            }

                            input {
                                display: none;
                            }
                        }

                        &VatError {
                            label {
                                background: rgba(190, 14, 14, 0.09);
                                color: var(--primary-error-color);
                            }
                        }

                        &Po_box {
                            inset-inline-start: 0;
                            inset-block-end: 60px;

                            label {
                                background: transparent;
                                color: var(--primary-error-color);
                                padding: 0 5px;
                            }

                            &.\31 {
                                @include desktop {
                                    inset-inline-start: 51%;
                                }
                            }

                            &.\32 {
                                inset-block-end: -20px;
                            }
                        }
                    }

                    &.Field_type_checkbox {
                        & ~ .Field_type_Po_box {
                            inset-block-end: 94px;

                            &.\32 {
                                inset-block-end: 22px;
                            }
                        }
                    }
                }

                @include desktop {
                    flex-direction: unset;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .Field {
                        width: 49%;
                    }
                }

                .Field_type_select {
                    order: 2;

                    & + .Field_type_select {
                        order: 1;
                    }
                }

                .state {
                    order: 1;
                }

                .vat {
                    order: 3;
                }

                .Field_type_textarea {
                    order: 2;
                    width: 100%;
                }

                .Field_type_checkbox {
                    order: 4;
                    width: 100%;
                    
                    label {
                        cursor: pointer;
                    }

                    @include mobile {
                        margin-top: 30px;
                    }
                }
            }
        }

        .CheckoutShipping {
            textarea {
                width: 100%;
                line-height: 1.25;
                border-radius: 0;
            }
        }

        .CheckoutBilling {
            display: flex;
            flex-direction: column;

            .CheckoutPayments {
                display: contents;
                
                &.free {
                    ul.CheckoutPayments-Methods {
                        min-height: unset;
                    }
                }
            }
            
            .P-BreadPay {
                display: none;
            }

            #bred_div {
                order: 1;

                ~ .CheckoutPayments .P-BreadPay {
                    display: block;
                }
            }

            div.Loader {
                position: fixed;
            }

            &-TermsAndConditions {
                border: 1px #ccc dashed;
                padding: 12px 12px;
                margin-block: 20px;

                .Field_type_checkbox {
                    order: -1;
                    margin-block-start: 0;
                    line-height: 1;
                }

                .CheckoutBilling-Link,
                .CheckoutBilling-TACLabel {
                    color: #303030;
                    font-weight: normal;
                    font-size: 14px;

                    .CheckoutBilling-TACLink {
                        margin-left: 3px;
                    }
                }
            }

            &-Button {
                width: 100%;
                font-size: 16px;
                padding: 5px 12px !important;
                border-radius: 999px;

                &:hover,
                &:focus {
                    border-radius: 999px;
                }
            }

            &-SummaryHeader {
                font-size: 22px;
                
                @include mobile {
                    padding-top: 20px;
                    border-top: 1px solid var(--expandable-content-divider-color);
                }
            }
        }

        .CheckoutPayments {
            .rccs {
                margin-block-end: 20px;
            }

            .CheckoutPayments-Methods {
                margin-block-end: 12px;
            }

            select,
            input {
                border: 2px solid rgba(0, 0, 0, 0.2);
                height: 38px;
                padding: 0 9px;
                width: 100%;
                margin-block: 0 15px;

                &[type="button"] {
                    @include button;

                    background-color: var(--text-red-color);
                    font-size: 16px;
                }
            }

            label {
                display: block;
            }

            .Field_type_checkbox {
                label {
                    display: inline-block;
                    vertical-align: top;
                    inset-block-start: -1px;
                }
            }

            .PayPal .paypal-buttons {
                z-index: 1;
            }

            form[name="paymentform"] {
                display: none;
            }
        }
    }

    &-YotpoCoupon {
        margin-top: 15px;
    }

    .AddressBilling .Field.Field_type_Po_box {
        display: none;
    }

    .CheckoutBilling-TACLink {
        font-weight: normal;
    }

    .CheckoutOrderSummary-ItemsInCart {
        font-size: 14px;
    }

    &Page-TotalDetails {
        margin-block-end: 50px;

        dt {
            display: flex;
            justify-content: space-between;
            margin-block-end: 12px;

            strong {
                width: 66%;
            }

            span {
                font-family: regular-bold, sans-serif;
                font-size: 16px;
            }

            .TaxLine {
                font-family: regular-book, sans-serif;
                font-size: 16px;
                font-weight: normal;
            }

            &.grand_total {
                border-top: 1px solid var(--primary-divider-color);
                border-bottom: 1px solid var(--primary-divider-color);
                font-size: 16px;
                padding: 15px 0;
                font-weight: bold;
            }
        }
    }

    .bread-check {
        background: #333;
        color: #fff;
        font-weight: 600;
        padding: 10px 15px;
        border-radius: 5px;
        margin: 0 auto;
    }

    .ExpandableContent-Content_isContentExpanded {
        padding-block-start: 0;
    }

    .CheckoutOrderSummary .CartItem-Wrapper {
        padding: 1rem 0;
    }

    .CheckoutOrderSummary .ProductPrice {
        color: var(--color-black);
        font-size: 16px !important;
    }

    .CheckoutPayment-Button {
        padding: 0;
    }

    .GiftCode_Input_UI {
        @include desktop {
            justify-content: unset;
            margin-block-start: 15px;

            .Field {
                margin-block-start: 0;
                flex: 1;
            }
        }
    }

    .CartPage-GiftDiscount {
        margin-block-end: 25px;

        @include mobile {
            padding: 18px 0;
            
            &.ExpandableContent {
                border-bottom: 1px solid var(--expandable-content-divider-color);
            }
        }

        .ExpandableContent-Button {
            padding: 0;

            &::after {
                height: 13px;
                right: 7px;
            }

            &::before {
                height: 13px;
                right: 7px;
            }

            .ExpandableContent-Heading {
                font-size: 22px;
            }
        }

        .ExpandableContent-Content_isContentExpanded {
            padding: 0;
        }

        .GiftCode_Input_UI {
            button.CartGiftCode-Button {
                margin: 0 0 0 10px;
                width: auto;
                white-space: nowrap;
                height: 35px;
                line-height: 30px;
                border-radius: 999px;
            }

            input {
                height: 35px;
                padding: 0 8px;
                font-size: 14px;
                border: 2px solid rgba(0, 0, 0, 0.2);
                border-right: none;
            }
        }

        .Balance_button {
            margin-block-end: 15px;

            button.CartGetBalance-Button.Button {
                height: 35px;
                line-height: 30px;
                background-color: var(--btn-color);
                margin-block-start: 0.5rem;
                border-radius: 999px;

                &:hover {
                    background-color: var(--text-red-color);
                }
            }
        }
    }

    .CheckedBalanceOutputSection {
        .CheckedBalanceOutputItem {
            margin-block-end: 10px;
            border-bottom: 1px solid var(--checkout-payment-border-color);
            padding-block: 3px 5px;

            > div {
                display: flex;
                justify-content: space-between;
                margin-block-end: 10px;

                > span {
                    &:first-child {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .CheckoutBilling-Checkbox {
        margin-block-end: 15px;
    }

    .AppliedGiftCodeItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-start: 5px;

        .RemoveGiftCode {
            margin-block-start: 0;
            line-height: 30px;
        }
    }

    .DiscountTabs {
        margin-block-start: 10px;

        .TabHeader {
            border-bottom: 1px solid var(--expandable-content-divider-color);
            text-transform: uppercase;
            font-family: regular-bold, sans-serif;

            span {
                padding: 10px 17px;
                font-size: 15px;
                display: inline-block;
                cursor: pointer;
                background-color: #fff;
                vertical-align: top;
                border: 1px solid var(--expandable-content-divider-color);
                border-bottom: none;

                &.act {
                    background-color: var(--primary-accent-color);
                    color: var(--btn-text-color);
                    border-color: var(--primary-accent-color);
                }

                @include mobile {
                    padding: 10px;
                }
            }
        }

        .CartPage-ExpandableContentContent {
            margin-block-start: 15px;
        }

        .CartPage-Discount {
            margin-block: 0 25px;
        }

        .ExpandableContent {
            border-top: none;
        }
    }

    .DiscountDetails {
        padding-block-start: 15px;

        .CartPage-Discount .ExpandableContent-Button {
            @include mobile {
                padding-block: 8px 5px;
            }
        }

        .Field {
            margin-block-start: 0;
        }
    }

    .TermsAndConditions {
        margin-block-end: 20px;

        .PrivacyConsentCheckboxes {
            &-Wrapper {
                border: 1px #ccc dashed;
                padding: 12px;
                margin-block-start: 5px;
            }

            &-Consent {
                align-items: flex-start;

                label.PrivacyConsentCheckboxes-BoxLabel {
                    p:first-child::before {
                        content: "*";
                        color: var(--primary-error-color);
                    }
                }

                .PrivacyConsentCheckboxes-RequiredMessage {
                    display: none;
                }
            }
        }
    }

    .amasty_affiliate_code {
        display: none;
    }
}

div.ExpandableContent-Heading {
    font-family: regular-bold, sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: none;
}

.Checkout-Heading {
    font-size: 19px;
}

div#bread-finance-btn-new {
    text-align: center;
    margin-bottom: 10px;

    a {
        font-size: 0;
        text-transform: uppercase;
        background: #3c3d3c;
        color: #FFFFFF;
        text-align: center;
        transition: all .3s ease;
        display: inline-block;
        padding: 13px 25px;
        min-width: 215px;
        border-radius: 5px;

        &::before {
            content: "Pay over time";
            font-size: 14px;
            font-weight: 600;
        }
        
        &:hover {
            opacity: .8;
        }
    }
}

div.yotpo-widget-checkout-redemptions-widget.margin-top {
    margin-top: 0;
}
