/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable rule-empty-line-before */
.CheckoutDeliveryOption {
    .freeshippingindicator {
        &-item {
            background: var(--text-blue-color);
            padding: 5px;
            width: 26px;
            height: 26px;
            line-height: 12px;
            text-align: center;
            border-radius: 999px;

            svg {
                color: #fff;
                font-size: 14px;
            }
        }
    }
    .Field [type="checkbox"] {
        + .input-control {
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            &::after {
                background-color: var(--input-border-color);
                border-radius: 50%;
                width: 10px;
                height: 10px;
                top: unset;
                left: unset;
                box-shadow: none;
            }
        }
        &:checked + .input-control::after {
            box-shadow: none;
            background-color: var(--primary-base-color);
        }
    }
}
