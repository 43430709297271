/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable rule-empty-line-before */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */

.CheckoutPayments-Methods {
    .CheckoutPayment {
        padding-block: 0;
        background-repeat: no-repeat;
        background-position: center right;

        &-Button {
            padding: 20px 15px !important;
            color: var(--text-light-black-color);
            max-width: calc(100% - 100px);
            align-items: center;

            &.zipPay {
                max-width: 100%;
            }

            strong {
                margin-block-start: 10px;
                display: block;
            }
        }

        &.P-Pay.by.Card,
        &.P-Credit.Card,
        &.P-Kreditkarte {
            background-image: url(../../images/payment_icons/visa.svg), url(../../images/payment_icons/mastercard.svg), url(../../images/payment_icons/amex.svg);
            background-position: calc(100% - 75px), calc(100% - 45px), calc(100% - 15px);
        }
        &.P-Credit.Card.EFT {
            background-image: url(../../images/payment_icons/visa.svg), url(../../images/payment_icons/mastercard.svg);
            background-position: calc(100% - 45px), calc(100% - 15px);
        }

        &.P-PayPal {
            background-image: url(../../images/payment_icons/paypal_icon.svg);
            background-position-x: calc(100% - 15px);
        }

        &.P-Klarna {
            background-image: url(../../images/payment_icons/Klarna.svg);
            background-size: 60px;
            background-position-x: calc(100% - 15px);
        }

        &.P-Google {
            background-image: url(../../images/payment_icons/g-pay.svg);
            background-size: 58px;
            background-position-x: calc(100% - 15px);
        }

        &.P-Apple {
            background-image: url(../../images/payment_icons/apple-pay-black.svg);
            background-size: 58px;
            background-position-x: calc(100% - 15px);
        }

        &.P-SOFORT {
            background-image: url(../../images/payment_icons/sofort.svg);
            background-size: 55px;
            background-position-x: calc(100% - 15px);
        }

        &.P-Giropay {
            background-image: url(../../images/payment_icons/giropay.png);
            background-size: 65px;
            background-position-x: calc(100% - 15px);
        }

        &.P-PayFlex {
            background-image: url(../../images/payment_icons/payflex.png);
            background-size: 80px;
            background-position-x: calc(100% - 15px);
        }
        &.P-BreadPay {
            background-image: url(https://www.frontrunneroutfitters.com/media/wysiwyg/bread-finance/powered-by-bread-finance.png);
            background-size: 140px;
            background-position-x: calc(100% - 15px);
            background-position-y: 15px;
        }
        &.PayJustNow::after {
            content: "";
            background-image: url(../../images/payment_icons/PayJustNow.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 55px;
            background-color: var(--payjustnow-bg-color);
            position: absolute;
            width: 65px;
            height: 36px;
            border-radius: 2px;
            inset-block-start: 10px;
            right: 15px;
        }

        &.accPayDis {
            pointer-events: none;
            opacity: .6;

            button {
                pointer-events: none;
            }

            .disText {
                display: inline;
            }
        }

        .disText {
            color: var(--text-red-color);
            padding-left: 10px;
            display: none;
        }

        .payment-m-item {
            flex: 1;
            display: flex;

            img.home-card-image {
                margin-left: auto;
            }
        }
        .tooltip {
            position: absolute;
            bottom: 30px;
            left: 8px;
            padding: 8px;
            color: var(--text-red-color);
            border-radius: 10px;
            text-align: center;
            font-size: 13px;
            display: none;

            @include mobile {
                position: static;
                padding-top: 0;
            }
        }
        .BreadPayBtn {
            margin-left: 15px;
            margin-bottom: 15px;
            display: none;
            &.displayBreadPay {
                display: block;
                line-height: 30px;
                font-size: 15px !important;
                margin-top: 0.5rem;
                &.bread-disable-false {
                    opacity: .5;
                    cursor: not-allowed;
                    &:hover {
                        ~.tooltip {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    
    .Field [type="checkbox"] {
        + .input-control {
            border-radius: 50%;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            &::after {
                background-color: var(--input-border-color);
                border-radius: 50%;
                width: 8px;
                height: 8px;
                top: unset;
                left: unset;
                box-shadow: none;
            }
        }
        &:checked + .input-control::after {
            box-shadow: none;
            background-color: var(--primary-base-color);
        }
    }
}
