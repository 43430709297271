/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */

.CheckoutAddressBook {
    &-Wrapper {
        select {
            text-overflow: ellipsis;
            padding-inline-end: 40px !important;
            width: 100%;
            display: block;
        }
    }

    &-Button {
        padding: 2px 12px;

        @include mobile {
            width: auto !important;
        }
    }

    .SelectedAddress {
        margin-block-end: 20px;
        line-height: 20px;

        &:empty {
            margin: 0;
        }

        & ~ .changeAddress {
            margin-left: auto;
        }
    }

    .changeAddress {
        max-width: fit-content;
        max-height: 37px;
        line-height: 34px;
        border-radius: 999px;
    }

    .Billing-address {
        line-height: 1.3;
    }
}
