/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */

.Checkout {
    background-color: var(--header-background);

    .Checkout-Wrapper {
        background-color: var(--header-background);

        @include desktop {
            max-width: 1170px;
            margin-inline: auto;
            position: static;
            padding: logical 40px 15px 35px;
            display: block;

            div#checkout_page {
                position: static;
                display: grid;
                grid-template-columns: 55% 42%;
                grid-gap: 3%;
            }
        }

        @media (min-width: 1600px) {
            max-width: 1405px;
            width: 100%;
        }

        .Checkout-ProgressSection {
            margin-block-end: 20px;

            .Checkout {
                &-Title {
                    flex: 1;
                    font-family: regular-bold, sans-serif;
                    font-weight: 400;
                }

                &-Step {
                    width: auto;
                }

                &-StepBarActive {
                    background-color: var(--primary-accent-color);
                }

                &-StepBarActive_isSecond {
                    inset-inline-start: 50%;
                }

                &-SelectedStep {
                    font-size: 32px;
                    font-weight: 400;
                }

                &-TotalSteps {
                    font-size: 18px;
                }
            }

            .checkout-back {
                margin-inline-end: 5px;

                .ChevronIcon {
                    fill: inherit;
                    height: auto;
                    width: 20px;
                    inset-block-start: 1px;
                }

                &:hover {
                    .ChevronIcon {
                        fill: var(--primary-base-color);
                    }
                }
            }
        }

        @include mobile {
            padding: 0 16px;
        }

        .fieldError {
            .CheckoutShipping-Button {
                opacity: .25;
                // pointer-events: none;

                &:hover {
                    ~ .tooltip {
                        display: block;
                    }
                }
            }
        }
    }

    .CheckoutPayment {
        .payment-m-item img {
            width: auto;
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
        }

        &-Button:not(.CheckoutPayment-Button_isSelected)::after {
            border: 1px solid #999;
            opacity: 1;
            height: 12px;
            transform: unset;
        }
    }

    .CheckoutDeliveryOptions {
        width: 100%;
        margin-block-end: 15px;

        .shipping_vat_alert {
            margin-block-start: 5px;
            font-style: italic;
            color: #666;
        }

        &-NoOptions {
            padding: 40px 15px 6px 20px;
        }
    }

    .CheckoutPayments-Methods {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        background-color: #fff;
        border-radius: 3px;
        min-height: 100px;

        .CheckoutDeliveryOption {
            padding-block: 0;

            &-Row {
                padding-left: 10px;
                color: #000;

                strong {
                    font-family: regular-bold, sans-serif !important;
                    font-size: 16px;
                    font-weight: 400;
                    white-space: normal;
                }

                * {
                    color: inherit !important;
                }

                svg.fa-truck-fast {
                    color: #fff !important;
                }
            }

            &-Button {
                padding: 20px 15px;
                align-items: center;
            }

            .Field {
                margin-top: 0;
            }
        }
    }

    .CheckoutShipping-Button {
        width: 100%;
        font-size: 16px;
        padding: 5px 12px !important;
        border-radius: 999px;

        &:hover, &:focus {
            border-radius: 999px;
        }

        .LockIcon {
            width: 16px;
            margin-inline-end: 5px;
        }
    }

    .Checkout-Coupon form.CartCoupon {
        @include desktop {
            display: flex;
            align-items: baseline;

            .CartCoupon-Button {
                line-height: 30px;
                width: auto;
            }
        }
    }

    padding-block: 0;

    .Checkout-Heading {
        font-size: 22px;
        font-weight: 400;
        margin-block-start: 0;
    }

    .CheckoutGuestForm {
        margin-block-start: 10px;

        .MyAccountOverlay-Buttons {
            display: inline-block;
            margin-inline-end: 10px;
            margin-block-start: 20px;
        }
    }

    .CheckoutOrderSummary {
        background-color: #fff;
        border: 1px solid var(--secondary-base-color);

        .CartOverlay-Items {
            padding-left: 0;
            padding-right: 0;

            .CartItem-Price_isCartOverlay {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        @include desktop {
            border-radius: 5px;
            padding-left: 20px;
            padding-right: 20px;
            //position: sticky;
            //-webkit-position: sticky;
            //top: 50px;
            //z-index: 1;
        }

        .CheckoutOrderSummary-OrderTotals .CheckoutOrderSummary-Text {
            text-align: end;
        }
    }

    .Checkout-OrderTotal,
    .CheckoutOrderSummary .ProductPrice,
    .CartCoupon-Title,
    legend.title.login {
        font-family: regular-bold, sans-serif;
        font-size: 21px;
        font-weight: 400;
        margin-block-end: 0;
    }

    legend.title.login {
        font-size: 26px;
    }

    .CartItem .CartItem-Qty input {
        font-size: 15px;
        border: none;
    }

    @include mobile {
        .CheckoutOrderSummary-SummaryItem {
            padding-block-end: 0;
        }
    }

    .CartCoupon-Title {
        margin-block-end: 15px;
    }

    .CartItem-Picture {
        padding-block-end: 70%;
    }

    .Field label {
        font-size: 14px;
        line-height: 18px;
        color: var(--text-light-black-color);
        font-weight: 400;
        vertical-align: middle;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    select,
    textarea {
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        height: 40px;
        padding: 0 9px;
        font-size: 14px;
        width: 100%;

        &:focus {
            border: 1px solid rgba(0, 0, 0, 0.4) !important;
        }
    }

    textarea {
        max-width: 100%;
        height: auto;
        padding: 10px;
    }

    .CartItem-Heading,
    .CartItem-SubHeading {
        font-size: 14px;
        margin-block-end: 0;
        white-space: normal;
    }

    @include desktop {
        .Checkout-Title {
            font-size: 2rem;
        }

        .CheckoutOrderSummary-Header span {
            font-family: regular-bold, sans-serif;
        }

        .Checkout-Step {
            max-width: 100%;
            width: 100%;
        }
    }

    ~ .NavigationTabs {
        display: none;
    }

    ~ .Header-Wrapper {
        .wishlist-elem,
        .Header-MyAccount {
            display: none;
        }
    }

    .MyAccountOverlay-Additional_state_forgotPassword {
        padding-block-start: 0;

        section:last-of-type {
            display: flex;
            padding-block-start: 12px;

            .Button_likeLink {
                margin: 0 0 0 5px;
            }
        }
    }

    .CheckoutPayments .iHNqDx {
        background-color: transparent;
        padding: 0;

        .cqQGRX,
        .hRfUdb,
        .fSWevF {
            background: transparent;
            border: 1px solid #ccc;
            color: #333;
            box-shadow: none;

            input[type="text"] {
                border: none !important;
                margin: 0 0 1px;

                &::-webkit-input-placeholder {
                    /* Edge */
                    color: #ddd;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #ddd;
                }

                &::placeholder {
                    color: #ddd;
                }
            }
        }

        button.hRfUdb,
        button.dYwNxH {
            background-color: var(--text-red-color);
            border: 1px solid rgba(0, 0, 0, 0.2);
            font-size: 17px;
            line-height: 36px;
            padding: 0px 10px;
            text-align: center;
            box-shadow: none;
            border-radius: 0;
            font-family: regular-bold, sans-serif;
            text-transform: uppercase;
            color: var(--btn-text-color);

            &:hover {
                background-color: var(--text-red-hover-color);
            }

            &[disabled] {
                opacity: 0.5;
            }
        }
    }

    .Stripe {
        order: 1;
    }

    .termNotAccepted {
        .google_pay, .apple_pay {
            .Stripe {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }

    .google_pay, .apple_pay {
        ~ .Checkout-StickyButtonWrapper { 
            display: none;
        }
    }

    .Checkout-StickyButtonWrapper {
        inset-block-end: var(--navigation-tabs-height);
        inset-inline-start: 0;
        background-color: var(--header-background);
        order: 1;

        @include desktop {
            inset-block-end: 0;
            position: -webkit-sticky;
            position: sticky;
            padding: 10px 0 15px 0;
            background-color: rgba(245, 245, 245, 0.7);
            backdrop-filter: blur(2px);
        }

        @include mobile {
            left: 0;
            right: 0;
            padding: 16px;
        }

        .Button {
            margin: 0;

            &[disabled] {
                pointer-events: unset;

                &:hover {                
                    ~ .tooltip { 
                        display: block;
                    }
                }
            }
        }

        .tooltip {
            position: absolute;
            top: -24px;
            left: 0;
            right: 0;
            padding: 8px;
            color: var(--text-red-color);
            border-radius: 10px;
            text-align: center;
            display: none;
        }
    }

    .CartItem .CartItem-Qty button {
        width: 22px;
        height: 22px;
        padding: 1px;
    }

    .CheckoutAddressBook {
        margin-block-start: 5px;
    }

    .CheckoutAddressBook-Wrapper {
        grid-column-gap: 20px;
        display: flex;
        align-items: flex-start;

        .clearAddress {
            padding: 0 30px;
            line-height: 34px;
        }
        
        .CheckoutAddressTable {
            .KeyValueTable {
                tr {
                    th,
                    td {
                        color: #000;
                    }
                }
            }
        }
    }
}
