/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */
/* stylelint-disable declaration-no-important */

.checkout-success {
    padding: 20px 15px;
    line-height: 1.3;

    @include mobile {
        max-width: 490px;
        margin-inline: auto;
    }

    @include desktop {
        max-width: 1170px;
        margin-inline: auto;
        padding-inline: 10px;
        margin-block-start: 50px;
        width: 100%;
    }

    @media (min-width: 1600px) {
        max-width: 1405px;
        width: 100%;
    }

    h2 {
        font-size: 25px;
        margin: 0 0 8px;
    }

    h3 {
        font-family: regular-bold, sans-serif;
        text-transform: uppercase;
        margin: 10px 0 10px;
    }

    hr {
        border-bottom: 1px solid #ddd;
        margin-block-end: 15px;
    }

    .order-inf {
        background: #f1f1f1;
        padding: 10px 13px;
        margin-block-end: 20px;
        border-radius: 10px;

        & > div {
            margin-block-end: 20px;

            & > div {
                margin-block-end: 10px;
            }
        }
    }

    .item-ord {
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 10px;

        h4 {
            font-size: 22px;
            font-weight: normal;
            margin-block-end: 10px;
        }

        .item {
            &-container {
                margin-block-end: 10px !important;
                border-bottom: 1px solid var(--primary-divider-color);
            }

            &-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
            }

            &-image {
                width: 90px;
                height: 67px;
                margin-right: 10px;
                float: left;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            &-name {
                font-family: Regular-Bold, sans-serif;
                font-weight: 300;
                font-size: 18px;
            }

            &-name,
            &-price {
                font-weight: bold;
                text-transform: uppercase;
                margin-block-end: 0 !important;
            }

            &-sku {
                color: var(--text-gray-color);
                font-size: 14px;
                font-family: Regular, sans-serif;
            }

            &-brand {
                color: var(--text-gray-color);
                font-size: 14px;
                text-transform: uppercase;
            }
        }

        .item,
        .item-bt {
            padding: 10px 8px;

            & > div {
                margin-block-end: 8px;

                & > div {
                    margin-block-end: 8px;
                }
            }

            &:nth-child(even) {
                background: #f1f1f1;
            }
        }

        .item-bt {
            background: #f1f1f1;
            border-radius: 5px;

            & > div {
                display: flex;
                justify-content: space-between;
            }

            &-grand-total {
                font-weight: bold;
                font-size: 16px;
                color: #000000;
            }
        }

        @include desktop {
            .item {
                overflow-y: auto;
                max-height: 400px;
            }
        }
    }

    .order-inf-wrap {
        @include desktop {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            & > div {
                width: 49%;
                margin-block-end: 5px !important;
            }
        }
    }

    ~ .NavigationTabs,
    & ~ .Breadcrumbs {
        display: none;
    }

    .CheckoutRegistrationLink {
        margin: 0 0 30px;
    }

    canvas#confetti-holder {
        position: absolute;
        tsop: 0;
        left: 0;
        z-index: -1;
    }
}

.Checkout-StickyButtonWrapper {
    margin-block-start: 20px;

    @include mobile {
        position: fixed;
        z-index: 1;
        inset-inline: 0;
        padding: 16px;
        background: #fff;
        inset-block-end: var(--nav-tabs-height);
    }
}

@include mobile {
    .continue_shopping-Button {
        width: 100%;
    }
}
