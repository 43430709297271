/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */

.CheckoutAddressForm {
    max-width: 470px;
}

.CitySuggestions {
    position: absolute;
    top: 320px;
    width: 49%;
    z-index: 1;
    left: 51%;
    max-height: 187px;
    background: #fff;
    overflow-y: auto;
    padding: 5px 10px;
    border: 1px solid #ddd;

    @include mobile {
        top: 641px;
        right: 0;
        left: 0;
        width: 100%;
    }

    ul li {
        padding: 1px 0;
        margin-block-end: 0;
        cursor: pointer;

        &::before {
            display: none;
        }
    }
}

.btn.clearAddress {
    position: absolute;
    top: -51px;
    line-height: 34px;
    min-width: 100px;
    left: 150px;
}
